export default function PortfolioTab() {
    const projects = [
        {
            title: 'Teknosa File Manager',
            image: '/images/teknosa_project.jpg',
            url: 'https://transfer.teknosa.com/',
            desc: "Teknosa File Manager ensures secure file sharing among Teknosa managers with SMS verification, enhancing data security and seamless communication.",
            category: "Frontend Development"
        },
        {
            title: 'Manas Dashboard',
            image: '/images/manas_project.jpg',
            url: 'https://manas.hepapp.com/',
            desc: "Manas Dashboard provides a user-friendly platform for effective data visualization and analysis, streamlining data management and reporting.",
            category: "Frontend Development"
        },
        {
            title: 'Findvice App Landing Page',
            image: '/images/findvice_project.jpg',
            url: 'https://findviceapp.com/',
            desc: "The Findvice App Landing Page promotes the Findvice mobile app, detailing its features and benefits to inform potential users and support app downloads.",
            category: "Frontend Development"
        },
        {
            title: 'Edetay Yapi Landing Page',
            image: '/images/edetayyapi_project.jpg',
            url: 'https://edetayyapi.com/',
            desc: "The Edetay Yapi Landing Page is a freelance project showcasing an construction company's services and contact information to attract potential clients.",
            category: "Frontend Development"
        },
        {
            title: 'AI Chatbot for Seamless Conversations',
            image: '/images/chatbot_project.jpg',
            url: 'https://keremunce.github.io/nextjs-AIChatbot/',
            desc: "A simple AI chatbot project developed to demonstrate integration with conversational AI, offering a clean design and functional chat interface for interactive user engagement.",
            category: "Frontend Development"
        },
        {
            title: 'Calculator App',
            image: '/images/calculator_project.jpg',
            url: 'https://keremunce.github.io/calculator/',
            desc: "A sleek and efficient calculator app designed by Kerem Unce. Perform basic arithmetic operations with ease on a user-friendly interface.",
            category: "Frontend Development"
        },
        {
            title: 'Task Manager',
            image: '/images/taskmanager_project.jpg',
            url: 'https://keremunce.github.io/calculator/',
            desc: "An organized and efficient task management tool designed to help users keep track of their tasks and deadlines. The interface is intuitive, making task creation and management straightforward.",
            category: "Frontend Development"
        }
    ]

    return (
        <>
            <section className="PortfolioTab-container">
                <div className="subtitle">
                    <p><i className="ti-user text-orange-600"></i>My Works</p>
                </div>
                <div className="title">
                    <h2>Portfolio</h2>
                </div>
                {/* Frontend Development Section */}
                <div className="category-section mt-12">
                <div className="category-header mb-8 inline-block">
                        <h3 className="text-2xl font-bold mb-2">
                            Frontend Development
                        </h3>
                        <div className="w-12 h-1 rounded bg-orange-600"></div>
                    </div>
                    <div className="inner-container">
                        {projects
                            .filter(project => project.category === "Frontend Development")
                            .map((project, index) => (
                                <div key={index} className="projectBox">
                                    <div className="img-container">
                                        <img src={project.image} alt="" />
                                    </div>
                                    <div className="content-container">
                                        <h3 className="title">{project.title}</h3>
                                        <p className="description">{project.desc}</p>
                                    </div>
                                    <div className="button-container">
                                        <a href={project.url} rel="noreferrer" target="_blank">View Project <i className="ti ti-arrow-top-right"></i></a>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </>
    )
}